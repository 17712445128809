export const DisputeFormModel = {
  formFields: {
    getStarted: {
      name: "getStarted"
    },
    tellUsMore: {
      name: "tellUsMore"
    }
  }
} as const;
